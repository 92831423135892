import React from 'react';

import { Box, CircularProgress, Container } from '@mui/material';

function Loading() {
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          '& div': {
            textAlign: 'center',
            lineHeight: '40px',
          },
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
}

export default Loading;
