import React, { useContext } from 'react';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { IconButton } from '@mui/material';

import { SettingsContext } from 'contexts/settingsContext';

function ThemeMode() {
  const { settings, saveSettings } = useContext(SettingsContext);

  function handleChangeTheme() {
    if (settings.theme === 'light') {
      return saveSettings({ ...settings, theme: 'dark' });
    }

    saveSettings({ ...settings, theme: 'light' });
  }

  return (
    <IconButton sx={{ mr: 2 }} onClick={handleChangeTheme}>
      {settings.theme === 'light' && <DarkModeOutlinedIcon />}

      {settings.theme === 'dark' && <LightModeOutlinedIcon sx={{ color: '#ffc675' }} />}
    </IconButton>
  );
}

export default ThemeMode;
