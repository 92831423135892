import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import Authentication from 'containers/authentication';
import NotFound from 'containers/notFound';

import Loading from './loading';

const Render = (path) => {
  return Loadable(lazy(() => import(`pages/${path}`)))();
};

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};

const publicRoutes = [
  {
    path: '/',
    element: <Navigate to={'/auth/login'} />,
  },
  {
    path: 'auth',
    element: <Authentication />,
    children: [
      {
        path: 'login',
        element: Render('authentication/login'),
      },
      {
        path: 'forgot',
        element: Render('authentication/forgot'),
      },
      {
        path: 'register',
        element: Render('authentication/register'),
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    path: 'admin',
    element: <Navigate to="/auth/login" />,
    children: [
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export { publicRoutes };
