import React from 'react';

import SettingsProvider from 'contexts/settingsContext';
import TitleContextProvider from 'contexts/titleContext';
import { UserDataProvider } from 'contexts/userDataContext';

export const ContextProvider = ({ children }) => {
  return (
    <UserDataProvider>
      <SettingsProvider>
        <TitleContextProvider>{children}</TitleContextProvider>
      </SettingsProvider>
    </UserDataProvider>
  );
};
