import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import Dashboard from 'containers/dashboard';
import NotFound from 'containers/notFound';

import Loading from './loading';

const Render = (path) => {
  return Loadable(lazy(() => import(`pages/${path}`)))();
};

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};

const privateRoutes = [
  {
    path: '/',
    element: <Navigate to={'/admin'} />,
  },
  {
    path: 'auth',
    element: <Navigate to="/admin" />,
    children: [
      {
        path: 'login',
        element: Render('authentication/login'),
      },
      {
        path: 'forgot',
        element: Render('authentication/forgot'),
      },
      {
        path: 'register',
        element: Render('authentication/register'),
      },
    ],
  },
  {
    path: 'admin',
    element: <Dashboard />,

    children: [
      {
        path: '',
        index: true,
        element: Render('dashboard/overview'),
      },
      {
        path: 'store',
        children: [
          { path: '', element: Render('dashboard/store/preferences') },
          { path: 'themes', element: Render('dashboard/store/themes') },
          {
            path: 'menus',
            children: [
              { path: '', element: Render('dashboard/store/menus/list') },
              { path: 'new', element: Render('dashboard/store/menus/new') },
            ],
          },
          { path: 'checkout', element: Render('dashboard/store/checkout') },
          {
            path: 'pages',
            children: [
              { path: '', element: Render('dashboard/store/pages/list') },
              { path: 'new', element: Render('dashboard/store/pages/new') },
            ],
          },
          { path: 'scripts', element: Render('dashboard/store/scripts') },
          {
            path: 'menu-visual',
            children: [
              { path: '', element: Render('dashboard/store/visual') },
              {
                path: 'new-banner',
                element: Render('dashboard/store/visual/pages/sectionsAndBanners/newSectionForm'),
              },
            ],
          },
        ],
      },
      {
        path: 'products',
        children: [
          { path: '', element: Render('dashboard/products/list') },
          { path: 'new', element: Render('dashboard/products/new') },
          {
            path: 'categories',
            children: [
              { path: '', element: Render('dashboard/products/categories/list') },
              { path: 'new', element: Render('dashboard/products/categories/new') },
            ],
          },
          { path: 'colors', element: Render('dashboard/products/colors') },
          {
            path: 'attributes',
            children: [
              { path: '', element: Render('dashboard/products/attributes/list') },
              { path: 'new', element: Render('dashboard/products/attributes/new') },
            ],
          },
          { path: 'stocks', element: Render('dashboard/products/stocks') },
        ],
      },
      {
        path: 'clients',
        children: [
          { path: '', element: Render('dashboard/clients/list') },
          { path: 'new', element: Render('dashboard/clients/new') },
          { path: 'edit', element: Render('dashboard/clients/edit') },
          { path: 'details', element: Render('dashboard/clients/details') },
        ],
      },
      {
        path: 'orders',
        children: [
          { path: '', element: Render('dashboard/orders/list') },
          { path: 'details', element: Render('dashboard/orders/details') },
          { path: 'drafts', element: Render('dashboard/orders/drafts') },
          {
            path: 'checkouts',
            children: [
              { path: '', element: Render('dashboard/orders/checkouts/list') },
              { path: 'details', element: Render('dashboard/orders/checkouts/details') },
            ],
          },
        ],
      },
      {
        path: 'reports',
        children: [
          { path: '', element: Render('dashboard/reports/analytics') },
          {
            path: 'sale-by-day',
            element: Render('dashboard/reports/sales/byDay'),
          },
          {
            path: 'sale-by-hour',
            element: Render('dashboard/reports/sales/byHour'),
          },
          {
            path: 'sale-by-state',
            element: Render('dashboard/reports/sales/byState'),
          },
          {
            path: 'sale-by-payment',
            element: Render('dashboard/reports/sales/byPayment'),
          },
          {
            path: 'sale-by-payment-status',
            element: Render('dashboard/reports/sales/byPaymentStatus'),
          },
          {
            path: 'sale-by-parcel',
            element: Render('dashboard/reports/sales/byParcel'),
          },
          {
            path: 'sale-by-carrier',
            element: Render('dashboard/reports/sales/byCarrier'),
          },
          {
            path: 'sale-by-device',
            element: Render('dashboard/reports/sales/byDevice'),
          },
          {
            path: 'sale-by-customer',
            element: Render('dashboard/reports/customers/saleByCustomer'),
          },
          {
            path: 'sale-by-gender',
            element: Render('dashboard/reports/customers/saleByGender'),
          },
          {
            path: 'sale-by-age-range',
            element: Render('dashboard/reports/customers/saleByAgeRange'),
          },
          {
            path: 'register-customer',
            element: Render('dashboard/reports/customers/register'),
          },
          {
            path: 'sale-by-coupon',
            element: Render('dashboard/reports/marketing/saleByCoupon'),
          },
          {
            path: 'sale-by-free-shipping',
            element: Render('dashboard/reports/marketing/saleByFreeShipping'),
          },
          {
            path: 'register-mailing',
            element: Render('dashboard/reports/marketing/registerMailing'),
          },
          {
            path: 'sale-by-campaign',
            element: Render('dashboard/reports/marketing/saleByCampaign'),
          },
          {
            path: 'sale-by-product',
            element: Render('dashboard/reports/products/saleByProduct'),
          },
          {
            path: 'sale-by-variation',
            element: Render('dashboard/reports/products/saleByVariation'),
          },
          {
            path: 'sale-by-brand',
            element: Render('dashboard/reports/products/saleByBrand'),
          },
          {
            path: 'sale-by-category',
            element: Render('dashboard/reports/products/saleByCategory'),
          },
          {
            path: 'sale-by-attribute',
            element: Render('dashboard/reports/products/saleByAttribute'),
          },
        ],
      },
      {
        path: 'settings',
        children: [
          { path: '', element: Render('dashboard/settings/indice') },
          { path: 'general', element: Render('dashboard/settings/general') },
          {
            path: 'payments',
            children: [
              { path: '', element: Render('dashboard/settings/payments/methods') },
              { path: 'new', element: Render('dashboard/settings/payments/new') },
            ],
          },
          {
            path: 'freight',
            children: [
              { path: '', element: Render('dashboard/settings/freight/list') },
              { path: 'new', element: Render('dashboard/settings/freight/new') },
            ],
          },
          { path: 'notifications', element: Render('dashboard/settings/notifications') },
          {
            path: 'users',
            children: [
              { path: '', element: Render('dashboard/settings/users/list') },
              { path: 'new', element: Render('dashboard/settings/users/new') },
            ],
          },
          { path: 'files', element: Render('dashboard/settings/files') },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export { privateRoutes };
