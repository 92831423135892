import React, { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

import { Avatar, Box, ButtonBase, Divider, Popover } from '@mui/material';

import { H6, Small, Tiny } from 'components/typography';

import { useUserContext } from 'contexts/userDataContext';

function ProfilePopover() {
  const anchorRef = useRef(null);
  const { clearUserData, user } = useUserContext();

  const [open, setOpen] = useState(false);

  function handleLogout() {
    clearUserData();
    toast.error('A sessão atual foi encerrada');
  }

  return (
    <>
      <ButtonBase ref={anchorRef} disableRipple onClick={() => setOpen(true)}>
        <Avatar
          src={null}
          sx={{
            width: 35,
            height: 35,
            color: '#fff',
            background: ({ palette }) => palette.link[300],
          }}
        >
          {user.name.charAt(0)}
        </Avatar>
      </ButtonBase>

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          paper: {
            sx: {
              width: '100%',
              minWidth: 200,
              maxWidth: 230,
              mt: 1,
              padding: '0.5rem 0',
            },
          },
        }}
        onClose={() => setOpen(false)}
      >
        <Box mt={-1} p={2}>
          <H6>{user.name}</H6>

          <Tiny display="block" fontWeight={500} color="text.disabled">
            {user.email}
          </Tiny>
        </Box>

        <Divider />

        <Box pt={1} onClick={() => setOpen(false)}>
          <Link to="/admin/account/edit">
            <Small
              sx={{
                display: 'block',
                p: '5px 1rem',
                mt: '3px',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: ({ palette }) => palette.divider,
                },
              }}
            >
              Editar conta
            </Small>
          </Link>

          <Link to="/admin/account/financial">
            <Small
              sx={{
                display: 'block',
                p: '5px 1rem',
                mt: '3px',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: ({ palette }) => palette.divider,
                },
              }}
            >
              Financeiro
            </Small>
          </Link>

          <Link to="/admin/help">
            <Small
              sx={{
                display: 'block',
                p: '5px 1rem',
                mt: '3px',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: ({ palette }) => palette.divider,
                },
              }}
            >
              Central de Ajuda
            </Small>
          </Link>

          <a href="/" target="_blank">
            <Small
              sx={{
                display: 'block',
                p: '5px 1rem',
                mt: '3px',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: ({ palette }) => palette.divider,
                },
              }}
            >
              Base de conhecimento
            </Small>
          </a>

          <Divider sx={{ my: 1 }} />

          <Small
            color="#ea6c6c"
            sx={{
              display: 'block',
              p: '5px 1rem',
              mt: '3px',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: ({ palette }) => palette.divider,
              },
            }}
            onClick={handleLogout}
          >
            Encerrar sessão
          </Small>
        </Box>
      </Popover>
    </>
  );
}

export default ProfilePopover;
