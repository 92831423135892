import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';

import { privateRoutes, publicRoutes } from 'routes';
import ThemeProvider from 'theme';

import { useUserContext } from 'contexts/userDataContext';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

function App() {
  const { user } = useUserContext();

  const allRoutes = useRoutes(user ? privateRoutes : publicRoutes);

  const toasterOptions = {
    style: { fontWeight: 500, fontFamily: "'Montserrat', sans-serif" },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen />
      <ThemeProvider>
        <Toaster toastOptions={toasterOptions} />
        {allRoutes}
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
