import React from 'react';

import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

export default [
  [
    {
      title: 'Visão Geral',
      icon: <HomeOutlinedIcon />,
      path: '/admin',
      children: [],
    },
    {
      title: 'Minha Loja',
      icon: <StorefrontOutlinedIcon />,
      path: '/admin/store',
      children: [
        { title: 'Menu Visual', path: '/admin/store/menu-visual' },
        { title: 'Temas', path: '/admin/store/themes' },
        { title: 'Navegação', path: '/admin/store/menus' },
        { title: 'Checkout', path: '/admin/store/checkout' },
        { title: 'Páginas', path: '/admin/store/pages' },
        { title: 'Scripts', path: '/admin/store/scripts' },
      ],
    },
    {
      title: 'Assinatura',
      icon: <StarBorderPurple500OutlinedIcon />,
      path: '/admin/signature',
      children: [],
    },
  ],
  [
    {
      title: 'Produtos',
      icon: <LocalOfferOutlinedIcon />,
      path: '/admin/products',
      children: [
        { title: 'Categorias', path: '/admin/products/categories' },
        { title: 'Cores', path: '/admin/products/colors' },
        { title: 'Atributos', path: '/admin/products/attributes' },
        { title: 'Estoques', path: '/admin/products/stocks' },
      ],
    },
    {
      title: 'Clientes',
      icon: <PeopleAltOutlinedIcon />,
      path: '/admin/clients',
      children: [],
    },
    {
      title: 'Pedidos',
      icon: <InboxOutlinedIcon />,
      path: '/admin/orders',
      children: [{ title: 'Carrinhos abandonados', path: '/admin/orders/checkouts' }],
    },
    {
      title: 'Relatórios',
      icon: <EqualizerOutlinedIcon />,
      path: '/admin/reports',
      children: [],
    },
  ],
  [
    {
      title: 'Marketing',
      icon: <RocketLaunchOutlinedIcon />,
      path: '/admin/marketing',
      children: [],
    },
    {
      title: 'Configurações',
      icon: <DisplaySettingsOutlinedIcon />,
      path: '/admin/settings',
      children: [],
    },
  ],
];
