import { useEffect, useState } from 'react';

const useLocalStorage = (key, initialValue) => {
  const [data, setData] = useState(initialValue);

  useEffect(() => {
    const getData = localStorage.getItem(key);

    if (getData) {
      setData(JSON.parse(getData));
    }
  }, [key]);

  const storeData = (key, updateValue) => {
    setData(updateValue);
    localStorage.setItem(key, JSON.stringify(updateValue));
  };

  return { data, storeData };
};

export default useLocalStorage;
