import React from 'react';

import {
  CssBaseline,
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
  responsiveFontSizes,
  GlobalStyles,
} from '@mui/material';

import useSettings from 'hooks/useSettings';

import BaseOptions from './baseOptions';
import ThemesOptions from './themesOptions';

import merge from 'lodash.merge';

function ThemeProvider({ children }) {
  const { settings } = useSettings();

  let themeOption = ThemesOptions[settings.theme];

  if (!themeOption) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    themeOption = ThemesOptions.light;
  }

  const merged = merge({}, BaseOptions, themeOption, {
    direction: settings.direction,
  });

  let theme = createTheme(merged);
  theme = responsiveFontSizes(theme);

  theme.shadows[1] = '0px 4px 23px rgba(0, 0, 0, 0.12)';
  theme.shadows[2] = '0px 0px 21px 1px rgba(0, 0, 0, 0.07)';
  theme.shadows[3] = '0px 10px 30px rgba(0, 0, 0, 0.1)';
  theme.shadows[4] = '0px 7px 30px 3px rgba(0, 0, 0, 0.05)';

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeProvider;
