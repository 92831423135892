import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { AppBar, Box, Breadcrumbs, Button, Toolbar, alpha, useMediaQuery } from '@mui/material';

import { Paragraph, Span } from 'components/typography';

import BreadcrumbsConfig from './breadcrumbsConfig';
import NotificationsPopover from './notificationsPopover';
import ProfilePopover from './profilePopover';
import ThemeMode from './themeMode';

function Topbar({ toggleSidebar }) {
  const { pathname } = useLocation();
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));

  return (
    <AppBar
      position="sticky"
      sx={{
        height: 67,
        p: '16px 24px',
        borderBottom: '1px solid',
        borderColor: ({ palette }) => alpha(palette.text.disabled, 0.1),
        boxShadow: 'none',
        justifyContent: 'center',
        bgcolor: ({ palette }) => palette.background.default,
        zIndex: 11,
      }}
    >
      <Toolbar
        sx={{
          '@media (min-width: 0px)': {
            minHeight: 'auto',
            pl: 0,
            pr: 0,
          },
        }}
      >
        {isDesktop && (
          <Breadcrumbs>
            {BreadcrumbsConfig[pathname] &&
              BreadcrumbsConfig[pathname].map((item, index) => {
                if (item.actived) {
                  return (
                    <Paragraph key={index} color="text.primary" fontWeight={500}>
                      {item.label}
                    </Paragraph>
                  );
                }

                return (
                  <Link to={item.path} key={index}>
                    <Span color="text.disabled">{item.label}</Span>
                  </Link>
                );
              })}
          </Breadcrumbs>
        )}

        {!isDesktop && (
          <Box sx={{ cursor: 'pointer' }} onClick={toggleSidebar}>
            <MenuRoundedIcon />
          </Box>
        )}

        <Box flexGrow={1} />

        {isDesktop && (
          <a href="/" target="_blank">
            <Button
              size="small"
              startIcon={<StorefrontOutlinedIcon />}
              sx={{
                mr: 3,
                p: '6px 14px',
                '&:hover': {
                  background: ({ palette }) => alpha(palette.link[100], 0.05),
                },
              }}
            >
              Acessar Loja
            </Button>
          </a>
        )}

        <ThemeMode />
        <NotificationsPopover />
        <ProfilePopover />
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;
