import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Card, Stack } from '@mui/material';

function Authentication() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/auth/login');
  }, []);

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
      <Card
        sx={{
          width: { xs: '100%', md: 450 },
          maxWidth: 600,
          minHeight: { xs: '100%', md: 'auto' },
          p: 4,
          boxShadow: 'rgb(0 0 0 / 3%) 0px 2px 1px -1px',
        }}
      >
        <Outlet />
      </Card>
    </Stack>
  );
}

export default Authentication;
