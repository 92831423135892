export default {
  light: {
    palette: {
      primary: {
        100: '#fff2e7',
        200: '#ffdab7',
        300: '#ffb46e',
        400: '#ff9d42',
        500: '#ff7a00',

        main: '#FF710F',
        dark: '#f16809',
        light: '#f7dac6',
      },
      secondary: {
        100: '#F9F9F9',
        200: '#ECEFF5',
        300: '#E5EAF2',
        400: '#94A4C4',
        500: '#1d2438',

        main: '#1d2438',
        light: '#F9F9F9',
      },
      link: {
        100: '#31c9ea',
        200: '#20b6d7',
        300: '#0ba1c2',
        400: '#00809d',
        500: '#006177',
      },
      error: {
        main: '#f56a50',
      },
      warning: {
        main: '#ffba00',
      },
      success: {
        main: '#00aa4f',
      },
      info: {
        main: '#07caeb',
      },
      divider: '#E5EAF2',
      background: {
        default: '#f3f4f9',
      },
      text: {
        link: {
          100: '#31c9ea',
          200: '#20b6d7',
          300: '#0ba1c2',
          400: '#00809d',
          500: '#006177',
        },
        default: '#FF710F',
        primary: '#1d2438',
        secondary: '#E5EAF2',
        disabled: '#94A4C4',
      },
      mode: 'light',
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            border: '1px solid #E5EAF2',
            borderRadius: 8,
          },
        },
      },
    },
  },
  dark: {
    palette: {
      primary: {
        100: '#fff2e7',
        200: '#ffdab7',
        300: '#ffb46e',
        400: '#ff9d42',
        500: '#ff7a00',

        main: '#FF710F',
        dark: '#f16809',
        light: '#f7dac6',
      },
      secondary: {
        100: '#F9F9F9',
        200: '#ECEFF5',
        300: '#E5EAF2',
        400: '#94A4C4',
        500: '#1d2438',

        main: '#1d2438',
        light: '#F9F9F9',
      },
      link: {
        100: '#31c9ea',
        200: '#20b6d7',
        300: '#0ba1c2',
        400: '#00809d',
        500: '#006177',
      },
      error: {
        main: '#f56a50',
      },
      warning: {
        main: '#ffba00',
      },
      success: {
        main: '#00aa4f',
      },
      info: {
        main: '#07caeb',
      },
      background: {
        default: '#18191a',
        paper: '#202225',
      },
      text: {
        default: '#FF710F',
      },
      mode: 'dark',
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: 'none',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            '& .MuiPopover-paper': {
              border: '1px solid rgba(255, 255, 255, 0.12)',
            },
          },
        },
      },
    },
  },
};
