import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Badge, Box, Divider, IconButton, Popover, Stack } from '@mui/material';

import { H4, H6, Paragraph, Tiny } from 'components/typography';

function NotificationsPopover() {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [list] = useState([]);

  return (
    <>
      <IconButton ref={anchorRef} sx={{ mr: 2 }} onClick={() => setOpen(true)}>
        <Badge
          color="primary"
          variant="dot"
          invisible={true}
          sx={{
            '& .MuiBadge-badge': {
              top: 3,
              right: 3,
            },
          }}
        >
          <NotificationsOutlinedIcon />
        </Badge>
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          paper: {
            sx: { width: '100%', minWidth: 250, maxWidth: 375, mt: 1, p: '0.5rem 0' },
          },
        }}
        onClose={() => setOpen(false)}
      >
        <H4 fontWeight="700" p={2}>
          Notificações
        </H4>

        <Divider />

        {list.length === 0 && (
          <Paragraph p={2} fontWeight="500" textAlign="center" color="text.disabled">
            Não há notificações
          </Paragraph>
        )}

        {list.map((msg, index) => (
          <Link key={index} to={msg.route}>
            <Stack direction="row" alignItems="center" sx={{ p: 2 }} onClick={() => setOpen(false)}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  p: '10px',
                  background: '#00000030',
                  borderRadius: '40px',
                  color: ({ palette }) => palette.primary,
                }}
              >
                <ForumOutlinedIcon />
              </Stack>

              <Box ml={2}>
                <H6>{msg.name}</H6>

                <Tiny display="block" fontWeight={500} color="text.disabled">
                  {msg.message}
                </Tiny>
              </Box>
            </Stack>
          </Link>
        ))}
      </Popover>
    </>
  );
}

export default NotificationsPopover;
