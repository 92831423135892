import React, { createContext } from 'react';

import useLocalStorage from 'hooks/useLocalStorage';

const initialSettings = {
  direction: 'ltr',
  theme: 'light',
  responsiveFontSizes: true,
};

export const SettingsContext = createContext({
  settings: initialSettings,
  saveSettings: (arg) => {},
});

const SettingsProvider = ({ children }) => {
  const { data: settings, storeData: setSettings } = useLocalStorage(
    'virashop::settings',
    initialSettings
  );

  const saveSettings = (updateSettings) => {
    setSettings(updateSettings);
  };

  return (
    <SettingsContext.Provider value={{ settings, saveSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
