import React, { createContext, useState, useContext } from 'react';

export const UserDataContext = createContext(null);

const UserDataProvider = ({ children }) => {
  const localStorageData = { ...localStorage };
  const localStorageKeys = Object.keys(localStorageData);
  const hasLoggedIn = localStorageKeys.filter((str) => str.includes('@virashop'));

  let userInitialData = null;

  if (hasLoggedIn.length) {
    const localStorageKey = hasLoggedIn[0];

    hasLoggedIn.forEach((key) => {
      if (key !== localStorageKey) localStorage.removeItem(key);
    });

    userInitialData = JSON.parse(localStorage[localStorageKey]);
  }

  const [user, setUser] = useState(userInitialData);

  const updateUserData = ({ key, newUserData }) => {
    setUser(newUserData);
    localStorage.setItem(key, JSON.stringify(newUserData));
  };

  const clearUserData = () => {
    setUser(null);
    localStorage.clear();
  };

  return (
    <UserDataContext.Provider value={{ updateUserData, clearUserData, user }}>
      {children}
    </UserDataContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext(UserDataContext);

  if (!context) {
    throw new Error('useUserData must be used within an UserDataProvider');
  }

  return context;
};

export { UserDataProvider, useUserContext };
