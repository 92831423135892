import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Container, useMediaQuery } from '@mui/material';

import Sidebar from './sidebar';
import Topbar from './topbar';

function Dashboard() {
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));

  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setShowSidebar(isDesktop);
  }, [isDesktop]);

  return (
    <Box
      sx={{
        width: { xs: '100%', md: `calc(100% - ${showSidebar ? '240px' : 0})` },
        pb: { xs: 5, md: 0 },
        ml: { xs: 0, md: `${showSidebar ? '240px' : 0}` },
        transition: 'all 0.3s',
      }}
    >
      <Sidebar showSidebar={showSidebar} toggleSidebar={() => setShowSidebar((state) => !state)} />

      <Topbar toggleSidebar={() => setShowSidebar((state) => !state)} />

      <Container maxWidth="lg" sx={{ pt: 4, pb: 5 }}>
        <Outlet />
      </Container>
    </Box>
  );
}

export default Dashboard;
