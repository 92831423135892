import React from 'react';
import { Link } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Stack } from '@mui/material';

import { H1, Paragraph } from 'components/typography';

function NotFound() {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '100%', position: 'absolute', textAlign: 'center' }}
    >
      <H1 color="text.default" fontSize={{ xs: 150, md: 200 }} fontWeight={600} lineHeight="200px">
        404
      </H1>

      <Paragraph>A página que está procurando não foi encontrada!</Paragraph>

      <Link to="/">
        <Box>
          <Paragraph
            color="link.300"
            fontSize={16}
            fontWeight={600}
            textTransform="uppercase"
            mt={14}
          >
            <ArrowBackIcon sx={{ mb: '-4px', mr: 1, fontSize: 18 }} /> Voltar ao inicio
          </Paragraph>
        </Box>
      </Link>
    </Stack>
  );
}

export default NotFound;
