import React from 'react';
import { Link } from 'react-router-dom';
import ScrollBar from 'simplebar-react';

import { Box, Divider, Drawer, Stack, alpha, useMediaQuery } from '@mui/material';

import { Paragraph } from 'components/typography';

import NavConfig from './navConfig';
import NavItem from './navItem';

function Sidebar({ showSidebar, toggleSidebar }) {
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));

  return (
    <Box sx={{ display: showSidebar ? 'block' : 'none' }}>
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          zIndex: ({ zIndex }) => zIndex.drawer + 6,
          '& .MuiDrawer-paper': {
            width: 240,
            borderRight: 'none',
            boxSizing: 'border-box',
            overflow: 'hidden',
          },
        }}
      >
        <ScrollBar style={{ maxHeight: 'calc(100% - 50px)' }}>
          <Link to="/admin">
            <Stack direction="row" justifyContent="flex-start" sx={{ height: 67, pl: 2 }}>
              <img src="/static/logo/virashop_default.svg" alt="logo" width={130} />
            </Stack>
          </Link>

          <Box pl={1} pr={1}>
            <Divider sx={{ borderColor: ({ palette }) => alpha(palette.text.disabled, 0.1) }} />

            <Box mt={3}>
              {NavConfig.map((navs, index) => (
                <Box key={index}>
                  {navs.map((page) => NavItem(page, isDesktop, toggleSidebar))} <Box mt={4} />
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={{ position: 'fixed', bottom: 0, p: '10px 15px 10px 20px' }}>
            <Paragraph color="text.disabled">
              © {new Date().getFullYear()}{' '}
              <Link to="https://splcomunic.com.br" target="_blank" rel="noopener noreferrer">
                {' SPL Comunic'}
              </Link>
            </Paragraph>
          </Box>
        </ScrollBar>
      </Drawer>

      {!isDesktop && (
        <Box
          sx={{
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: ({ zIndex }) => zIndex.drawer - 1,
          }}
          onClick={toggleSidebar}
        />
      )}
    </Box>
  );
}

export default Sidebar;
