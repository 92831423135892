import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, alpha } from '@mui/material';

import { Span } from 'components/typography';

function NavItem(page, isDesktop, toggleSidebar) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [activePath, setActivePath] = useState(pathname);
  const [submenu, setSubmenu] = useState(undefined);
  const [submenuList, setSubmenuList] = useState([]);
  const [activeSubmenu, setActiveSubmenu] = useState(undefined);

  useEffect(() => {
    setActivePath(pathname);
  }, [pathname]);

  function handleNavigate(page) {
    setActivePath(page.path);
    navigate(page.path);

    setSubmenu(page.path);
    setSubmenuList(page.children);
    setActiveSubmenu(undefined);

    if (!isDesktop) toggleSidebar();
  }

  function handleNavigateSubmenu(page) {
    setActiveSubmenu(page.path);
    navigate(page.path);

    if (!isDesktop) toggleSidebar();
  }

  function handleActiveMenu(page) {
    if (activeSubmenu && page.children.length > 0) {
      if (activePath.indexOf(page.path) > -1) {
        return '#0BA1C2';
      }
    }
  }

  return (
    <Box key={page.title}>
      <Button
        active={(page.path === activePath).toString()}
        sx={{
          justifyContent: 'left',
          p: '0.6rem 0.5rem',
          borderRadius: '0px',
          fontWeight: 'bold',
        }}
        fullWidth
        onClick={() => handleNavigate(page)}
      >
        {page.icon && (
          <Box
            color={() => handleActiveMenu(page)}
            sx={{
              mr: '12px',
              mb: '-6px',
              svg: {
                color: ({ palette }) =>
                  page.path === activePath ? palette.link[200] : palette.primary,
                fontSize: 22,
              },
            }}
          >
            {page.icon}
          </Box>
        )}

        <Span
          sx={{
            color: ({ palette }) => page.path === activePath && palette.link[200],
          }}
        >
          {page.title}
        </Span>
      </Button>

      {submenuList.length > 0 && submenu === page.path && (
        <Box mb={1}>
          {submenuList.map((submenu) => (
            <Button
              key={submenu.title}
              active={(submenu.path === activeSubmenu).toString()}
              sx={{
                p: '0.6rem 0.5rem',
                justifyContent: 'left',
                borderRadius: '0px',
                fontWeight: 600,
                span: {
                  color: ({ palette }) =>
                    activeSubmenu === 'true' ? palette.link[200] : palette.text.disabled,
                },
                '&:hover': {
                  background: ({ palette }) => alpha(palette.link[100], 0.05),
                },
              }}
              fullWidth
              onClick={() => handleNavigateSubmenu(submenu)}
            >
              <Span sx={{ ml: '35px' }}>{submenu.title}</Span>
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default NavItem;
