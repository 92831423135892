import React, { useEffect, createContext, useState } from 'react';

export const TitleContext = createContext({
  title: '',
  setTitle: (arg) => {},
});

const TitleContextProvider = ({ children }) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (title) {
      window.document.title = `Virashop — ${title}`;
    }
  }, [title]);

  return <TitleContext.Provider value={{ title, setTitle }}>{children}</TitleContext.Provider>;
};

export default TitleContextProvider;
